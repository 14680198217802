// Here you can add other styles

//User Box like Header Section
.userBox {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1.75rem;
  margin-bottom: 0rem;
  align-items: center;
}
.userBox i {
  width: 75%;
  height: 75%;
  object-fit: cover;
  border-radius: 50%;
}
.userBox h4 {
  margin-bottom: 0.1rem;
  text-transform: uppercase;
}
.userBox p {
  margin-bottom: 0;
}

//Main Menu Box
.mainMenuBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 0.75rem;
  column-gap: 1.25rem;
  justify-content: center;
}
.mainMenuItem {
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 0.1rem;
  padding: 0.5rem;
  width: 130px;
  height: 130px;
}
.mainMenuItem img {
  width: 68%;
  // height: 75%;
}
.mainMenuItem p {
  margin-bottom: 0;
}
